/****************************************************************************
 * Copyright 2021 EPAM Systems
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 ***************************************************************************/
import React, { useEffect, useRef } from 'react'
import { useSettingsContext } from '../../../../hooks'

interface AppHiddenCallProps {
  onInitTmpls: (cacheEl, url) => void
}

type Props = AppHiddenCallProps
// todo: come up with better name
const AppHidden = (props: Props) => {
  const { onInitTmpls } = props

  const ref = useRef(null)
  const { staticResourcesUrl } = useSettingsContext()

  useEffect(() => {
    onInitTmpls(ref.current, staticResourcesUrl)
  }, [])

  return <div style={{ display: 'none' }} ref={ref} />
}

export type { AppHiddenCallProps }
export { AppHidden }
